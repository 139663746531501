import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { useState } from 'react'
import * as styles from './tooltip.css.ts'

interface TooltipProps {
  children: React.ReactNode
  iconClassName?: string
}

// Necessary to enable tooltip on click on mobile since Radix designers won't add this functionality
// https://github.com/radix-ui/primitives/issues/955
function TooltipWithClickToOpen({ children, iconClassName }: TooltipProps) {
  const [open, setOpen] = useState(false)

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
        <RadixTooltip.Trigger asChild>
          <span
            onClick={() => setOpen(true)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <InfoCircledIcon className={classNames(styles.infoIcon, iconClassName)} />
          </span>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className={styles.tooltip} sideOffset={3} asChild>
            <p className={styles.tooltipContent}>{children}</p>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

export { TooltipWithClickToOpen as Tooltip }
