import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useEnvironmentData } from '../hooks/use-environment-data'

export const WithHubspot = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const environmentData = useEnvironmentData()

  useEffect(() => {
    const syncUser = async () => {
      // Only sync in local dev or pixee saas environments
      if (environmentData.name !== 'local-dev' && environmentData.name !== 'pixee-saas') {
        return
      }

      try {
        await fetch('/api/user/sync', {
          method: 'POST',
          credentials: 'include',
        })
      } catch (error) {
        // Silently fail - non-critical operation
        console.warn('Failed to sync user with Hubspot:', error)
      }
    }

    syncUser()
  }, [location.pathname, environmentData.name])

  return <>{children}</>
}
