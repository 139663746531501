import { FC } from 'react'
import { match } from 'ts-pattern'
import { Fix } from '../utils/api-client/user-platform-api-schemas'
import * as styles from './fix-tag.css'

interface FixTagProps {
  strategy: Fix['strategy']
}

export const FixTag: FC<FixTagProps> = ({ strategy }) => {
  const tag = match(strategy)
    .with('deterministic', () => (
      <span role="status" aria-label="Deterministic fix" className={styles.aiAndDeterministicBadge}>
        Deterministic
      </span>
    ))
    .with('ai', () => (
      <span role="status" aria-label="AI generated fix" className={styles.aiAndDeterministicBadge}>
        AI
      </span>
    ))
    .with('hybrid', () => (
      <span role="status" aria-label="AI generated fix" className={styles.aiAndDeterministicBadge}>
        Hybrid
      </span>
    ))
    .with('provisional_ai', () => (
      <>
        <span role="status" aria-label="AI generated fix" className={styles.aiAndDeterministicBadge}>
          AI
        </span>
        <span role="status" aria-label="Provisional status" className={styles.provisionalBadge}>
          Provisional
          <span className={styles.provisionalBadgeIcon}>🪄 </span>
        </span>
      </>
    ))
    .exhaustive()

  return (
    <div role="group" aria-label="Fix type indicators" className={styles.fixTypeIndicators}>
      {tag}
    </div>
  )
}
